import React, { useState } from 'react';

interface SliderProps {
  min: number;
  max: number;
  increment: number;
  color: string;
  initalVal: number;
  onDrop: (value: number) => void;
  onChange: (value: number) => void;
}

const Slider = ({ min, max, increment, initalVal, onDrop, onChange, color }: SliderProps) => {
  const [value, setValue] = useState(initalVal);

  const handleChange = (event: any) => {
    onChange!(event.target.value);
    setValue(event.target.value);
  };

  const handleMouseUp = () => {
    // if (onDrop) {
    //   onDrop(value);
    // }
  };

  const fillPercentage = ((value - min) / (max - min)) * 100;

  const id = `slider-${Math.random().toString(36).substr(2, 9)}`;

  const sliderStyles = `
      #${id}::-webkit-slider-runnable-track {
        width: ${fillPercentage};
        height: 2px;
        background: ${color};
        border-radius: 6px;
        margin-top: 4px;
      }
      #${id}::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 16px;
        width: 24px;
        border-radius: 12px;
        background: #FCFCFC;
        border: 3px solid ${color};
        cursor: pointer;
        margin-top: -8px;
        cursor: pointer;
      }
      #${id}:focus {
        outline: none;
      }
    `;

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: sliderStyles }} />
      <div className="Slider relative w-full h-6 flex justify-start">
        {/* Slider Thumb */}
        <input
          type="range"
          min={min}
          id={id}
          max={max}
          step={increment}
          value={value}
          className={`w-full h-[2px] appearance-none cursor-pointer bg-transparent`}
          onChange={handleChange}
          onMouseUp={handleMouseUp}
        />
      </div>
    </>
  );
};

export default Slider;
