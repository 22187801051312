import {
  BackSide,
  BufferAttribute,
  Color,
  ColorRepresentation,
  CubeTexture,
  Fog,
  GLSL3,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Quaternion,
  Scene,
  ShaderMaterial,
  SphereGeometry,
  Texture,
  Vector3,
  WireframeGeometry,
  Matrix4,
  MathUtils,
  MeshStandardMaterial,
  Light,
  PlaneGeometry,
  ShadowMaterial,
  DirectionalLight,
  Plane,
  CameraHelper,
} from 'three';
import { RadicalCharacter } from '@radical/radical-character-three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GroundedSkybox } from 'three/examples/jsm/objects/GroundedSkybox';
import { AssetModelLoaded, ObjectSnapshot } from '@radical/canvas-fe-types';
import { UserCamera } from '../user-camera';
import { AssetObject } from '../radical-object';
import { generateGrayscaleColor } from '../../helpers/ColorHelpers';

//@ts-ignore
import filePathCharPlaceholder from '../../public/assets/male_body_base_mesh.glb';
//@ts-ignore
import filePathVisualizerPlane from '../../public/assets/Floor_core_2.glb';
import {
  CanvasCallbacksNames,
  CanvasCallbacksTypes,
  ObjectType,
  VisitorInfo,
  AssetModelInfo,
  CameraInfo,
  CharacterModelInfo,
  EnvSettings,
  LightInfo,
} from '@radical/canvas-types';
import { CinematicCamera } from '../cinematic-camera';
import { RadicalPointLight, RadicalSpotLight } from '../radical-lights';
import { DEG2RAD } from 'three/src/math/MathUtils';

// Define the type for a 3D visitor including their camera and information
type Visitor3D = {
  cone: UserCamera;
  visitor: VisitorInfo;
};

const paramsGroundedSkybox = {
  height: 15,
  radius: 1000,
  enabled: true,
};

// The main class for managing a 3D stage in the editor
export class RadicalStage extends Scene {
  private ownId: string | undefined; // Unique identifier for the current user or session, potentially used for distinguishing user-specific data or actions.
  private assetsInScene: Map<string, AssetObject | Object3D>; // A collection mapping unique asset identifiers to their corresponding 3D objects or custom asset objects within the scene, facilitating asset management and manipulation.
  private asetsInUI: Map<string, string>; // A mapping between asset identifiers and their names as displayed in the UI, aiding in the linkage between scene objects and their UI representations.
  private camerasInUI: Map<string, string>; // A mapping between camera identifiers and their names as displayed in the UI, aiding in the linkage between scene objects and their UI representations.
  private lightsInUI: Map<string, string>; // A mapping between light identifiers and their names as displayed in the UI, aiding in the linkage between scene objects and their UI representations.
  private charactersInScene: Map<string, RadicalCharacter>; // A collection of characters present in the scene, keyed by a unique identifier, allowing for efficient character data retrieval and updates.
  private charactersInUI: Map<string, string>; // Similar to `asetsInUI`, this maps character identifiers to their names within the UI, supporting the connection between scene characters and their UI labels.
  private loaderModelChar: Object3D = new Object3D(); // A placeholder 3D object used as a template for loading character models, ensuring a consistent base for all characters added to the scene.
  private camerasInScene: Map<string, CinematicCamera>; // Stores a collection of cinematic cameras camera objects within the scene, indexed by a unique identifier, allowing for easy camera access and management.
  private lightsInScene: Map<string, RadicalPointLight | RadicalSpotLight>; // Stores a collection of lights within the scene, indexed by a unique identifier, allowing for easy light access and management.
  private callbacks?: CanvasCallbacksTypes; // Optional callbacks that can be triggered on specific canvas events, providing a mechanism for event-driven interactions within the scene.
  private duration: number = 30 * 60; // Default duration for certain animations or events within the scene, set to 30 minutes (30 * 60 seconds).

  private usersInScene: Map<string, Visitor3D> = new Map<string, Visitor3D>(); // Tracks users currently in the scene, with each entry containing both the camera view and additional visitor information for that user.
  private userHolders: Object3D = new Object3D(); // A container object in the scene dedicated to holding user-related objects, such as cameras or avatars, to organize and manage them efficiently.

  private followingUser: Visitor3D | undefined; // The user (if any) that the current session is set to follow within the scene, allowing for camera or focus adjustments based on this user's position and actions.
  private jointsVisible: boolean = false; // A flag indicating whether the joints of characters in the scene are currently visible, typically used for debugging or detailed character inspections.
  private sceneNamesVisible: boolean = false; // Controls the visibility of scene names, enabling or disabling the display of names or labels associated with objects or areas within the scene.
  private backgroundEnvVisible: boolean = false; // Determines whether the background environment is currently visible, allowing for dynamic changes to scene aesthetics or background elements.
  private userPovVisible: boolean = true; // Indicates whether the point-of-view (POV) of users' cameras should be visible, affecting how user perspectives are represented within the scene.
  private envSettings: EnvSettings = { floorProjection: false, intensity: 1, rotation: 0 }; // The environment map settings
  private floor: Object3D; // Freates floor for core scenes and live

  private groundedSkybox: GroundedSkybox | undefined;
  private backgroundMesh: Mesh; // Mesh to replace the scene.background
  private backgroundTexture: Texture; // The equirectangular background texture, prior the pmrem
  private backgroundUniforms: any;

  private numTotalLightsAddedToScene: number = 0;
  private shadowCatchingPlane: Mesh = new Mesh(new PlaneGeometry(2000, 2000), new ShadowMaterial());
  private directionalLight: DirectionalLight = new DirectionalLight(0xffddbb, 1);

  /**
   * Constructor for the `RadicalStage` class, which represents a 3D scene.
   * @param callbacks Optional canvas event callbacks for handling interactions within the scene.
   * @param isVisualizer A boolean flag indicating whether this instance is a visualizer, typically used for special scene configurations.
   */
  constructor(
    callbacks?: CanvasCallbacksTypes,
    isVisualizer?: boolean,
    isLive?: boolean,
    backgroundColor?: ColorRepresentation
  ) {
    // Call the constructor of the parent class (`Scene`) to initialize the scene.
    super();

    // Add a container object (`userHolders`) to the scene for organizing user-related objects.
    this.add(this.userHolders);

    // Initialize collections to manage assets, characters, and UI labels within the scene.
    this.assetsInScene = new Map<string, AssetObject>();
    this.charactersInScene = new Map<string, RadicalCharacter>();
    this.charactersInUI = new Map<string, string>();
    this.asetsInUI = new Map<string, string>();
    this.camerasInUI = new Map<string, string>();
    this.lightsInUI = new Map<string, string>();

    // Initialize a collection to manage cameras within the scene.
    this.camerasInScene = new Map<string, CinematicCamera>();

    // Initialize a collection to manage lights within the scene.
    this.lightsInScene = new Map<string, RadicalPointLight | RadicalSpotLight>();

    // Create and initialize a placeholder 3D object (`loaderModelChar`) used for loading character models.
    this.loaderModelChar = this.createLoaderMesh();

    // Check if the scene is set to be a visualizer, and if so, add a visualizer floor object to the scene.
    this.floor = this.createVisualizerFloor(isLive);
    this.add(this.floor);
    this.floor.visible = false;
    if (isVisualizer) {
      this.floor.visible = true;
    }

    // Initialize canvas event callbacks if provided.
    if (callbacks) this.callbacks = callbacks;

    //

    // Create a mesh sphere to use as a background
    // This is used instead of the default scene.background to allow for more complex shader effects,
    // such as Multi-Render Targets (MRT) and selective bloom effects.
    const geometry = new SphereGeometry(10000, 32, 32);
    const material = new MeshBasicMaterial({ side: BackSide, transparent: true, depthWrite: false }); // Material that doesn't react to light and renders the inside of the geometry
    this.backgroundMesh = new Mesh(geometry, material); // Create the mesh with the specified geometry and material
    this.backgroundMesh.renderOrder = -99999; // Ensures this mesh is rendered first, behind all other objects
    this.backgroundMesh.scale.x = -1;
    this.backgroundMesh.rotateY(Math.PI);

    this.backgroundTexture = new Texture(); // Initialize to empty texture

    // Convert the provided background color to an RGB color object
    const backgroundColorRGB = new Color(backgroundColor);

    // Define custom uniforms for the shader. Uniforms are global GLSL variables that remain constant for all vertices/fragments for a single draw call
    this.backgroundUniforms = {
      backgroundColor: { value: new Vector3(backgroundColorRGB.r, backgroundColorRGB.g, backgroundColorRGB.b) }, // The background color uniform
      isEnvironment: { value: false }, // A flag to indicate whether the background should be treated as an environment map
    };

    // Modify the shader code before compilation
    //@ts-ignore
    this.backgroundMesh.material.onBeforeCompile = (shader: any) => {
      shader.glslVersion = GLSL3; // Specify the GLSL version to use

      shader.uniforms = {
        ...shader.uniforms,
        ...this.backgroundUniforms,
      };

      // Insert custom GLSL code and uniforms into the fragment shader
      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <common>`,
        `#include <common>
        uniform vec3 backgroundColor;
        uniform bool isEnvironment;
        layout(location = 0) out vec4 base_FragColor;
        layout(location = 1) out vec4 bloom_FragColor;
        layout(location = 2) out vec4 depth_FragColor;
        layout(location = 3) out vec4 id_FragColor;
        `
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <opaque_fragment>`,

        `#ifdef OPAQUE
        diffuseColor.a = 1.0;
        #endif
        
        #ifdef USE_TRANSMISSION
        diffuseColor.a *= material.transmissionAlpha;
        #endif
        
        bloom_FragColor = vec4( 0.0, 0.0, 0.0, diffuseColor.a );
        base_FragColor = vec4( mix(backgroundColor, outgoingLight, float(isEnvironment)), diffuseColor.a );
        depth_FragColor = vec4( 1.0, 1.0, 1.0, diffuseColor.a );
        id_FragColor = vec4( 1.0, 1.0, 1.0, diffuseColor.a );
        `
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <tonemapping_fragment>`,
        `#if defined( TONE_MAPPING )
          bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
          base_FragColor.rgb = toneMapping( base_FragColor.rgb );
        #endif`
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <colorspace_fragment>`,
        `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
        base_FragColor = linearToOutputTexel( base_FragColor );
        `
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <fog_fragment>`,
        `#ifdef USE_FOG
          #ifdef FOG_EXP2
            float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
          #else
            float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
          #endif
          bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
          base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
      #endif`
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <premultiplied_alpha_fragment>`,
        `#ifdef PREMULTIPLIED_ALPHA
          // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
          bloom_FragColor.rgb *= bloom_FragColor.a;
          base_FragColor.rgb *= base_FragColor.a;
        #endif`
      );

      shader.fragmentShader = shader.fragmentShader.replace(
        `#include <dithering_fragment>`,
        `#ifdef DITHERING
          bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
          base_FragColor.rgb = dithering( base_FragColor.rgb );
        #endif`
      );
    };

    this.addToScene(this.backgroundMesh);

    this.initializeEnvironment();
  }

  private initializeEnvironment() {
    // Creating a new directional light with white color (0xffffff) and full intensity (1).
    // const dlight = new DirectionalLight(0xffffff, 1);
    this.directionalLight.position.set(13, 12, 8); // Setting the position of the directional light in the 3D space.
    this.directionalLight.target.position.set(0, 0, 0);
    this.add(this.directionalLight.target);
    this.directionalLight.castShadow = true; // Enabling shadow casting for the light.

    // Configuring the shadow camera
    this.directionalLight.shadow.camera.near = 0.1; // The closest distance from the camera where shadows start.
    this.directionalLight.shadow.camera.far = 75; // The farthest distance from the camera where shadows end.
    // Setting the boundaries of the shadow camera's view.
    this.directionalLight.shadow.camera.right = 17;
    this.directionalLight.shadow.camera.left = -17;
    this.directionalLight.shadow.camera.top = 17;
    this.directionalLight.shadow.camera.bottom = -17;
    this.directionalLight.shadow.mapSize.width = 1024; // Horizontal resolution of the shadow map.
    this.directionalLight.shadow.mapSize.height = 1024; // Vertical resolution of the shadow map.
    this.directionalLight.shadow.radius = 4; // The radius to use for shadow blurring.
    this.directionalLight.shadow.blurSamples = 8; // Number of samples to take for blurring the shadow.
    this.directionalLight.shadow.bias = -0.0001; // Adjusting the shadow bias to prevent rendering artifacts.
    this.directionalLight.shadow.normalBias = -0.0005; // Adjusting the shadow bias to prevent rendering artifacts.

    // Adding the directional light to the scene.
    // this.lights.directional.push(this.directionalLight); // Adding to the array of directional lights.
    this.add(this.directionalLight); // Adding the light to the 3D scene.

    // const helper = new CameraHelper( this.directionalLight.shadow.camera );
    // this.add( helper );

    // Shadow catching plane
    this.shadowCatchingPlane.geometry.rotateX(-Math.PI / 2);
    (this.shadowCatchingPlane.material as ShaderMaterial).opacity = 2 / 3;
    this.shadowCatchingPlane.receiveShadow = true;
    this.shadowCatchingPlane.position.setY(0.001);
    this.shadowCatchingPlane.renderOrder = 100;
    this.shadowCatchingPlane.frustumCulled = false;
    this.add(this.shadowCatchingPlane);
  }

  public setFloorShadowProjectionVisibility(b: boolean) {
    this.shadowCatchingPlane && (this.shadowCatchingPlane.visible = b);
  }

  // Toggles shadow catching plane & environment map rotation
  public updateSceneEnvironment(settings: Partial<EnvSettings>) {
    const { floorProjection, intensity, rotation } = settings;

    if (floorProjection !== undefined) {
      this.envSettings.floorProjection = floorProjection;
      this.setFloorProjection(floorProjection);
    }

    if (intensity !== undefined) {
      this.envSettings.intensity = intensity;
      this.setEnvIntensity(intensity);
    }

    if (rotation !== undefined) {
      this.envSettings.rotation = rotation;
      this.setEnvRotation(rotation);
    }
  }

  // Get the background mesh
  public getBackgroundMesh(): Mesh {
    return this.backgroundMesh;
  }

  //  Returns a cloned instance of the character loader model (`loaderModelChar`).
  public getCharLoaderClone(): Object3D {
    return this.loaderModelChar.clone();
  }

  // Adds an object (either an AssetObject or Object3D) to the scene and manages it in the `assetsInScene` collection.
  public addToScene(obj: AssetObject | Object3D) {
    this.assetsInScene.set(obj.uuid, obj);
    this.add(obj);
  }

  // Sets the visibility of scene names (UI labels) within the 3D scene.
  public setSceneNamesVisible(b: boolean) {
    this.sceneNamesVisible = b;
  }

  // Retrieves the visibility status of scene names (UI labels) within the 3D scene.
  public getSceneNamesVisible(): boolean {
    return this.sceneNamesVisible;
  }

  // Sets the rotation of the environment and environment mesh
  public setEnvRotation(n: number) {
    const rad = MathUtils.degToRad(n);
    this.environmentRotation.set(0, rad, 0);
    this.envSettings.rotation = n;

    if (this.backgroundMesh !== undefined) {
      this.backgroundMesh.rotation.set(0, Math.PI + rad, 0);
    } else {
      console.error('Cant set rotation on backgroundMesh');
    }

    if (this.groundedSkybox !== undefined) {
      this.groundedSkybox!.rotation.set(0, rad, 0);
    } else {
      console.error('Cant set rotation on groundedSkybox');
    }

    if (!this.directionalLight || this.directionalLight instanceof DirectionalLight === false) {
      return;
    }

    const radius = Math.sqrt(
      this.directionalLight.position.x * this.directionalLight.position.x +
        this.directionalLight.position.z * this.directionalLight.position.z
    );
    // Calculate new positions
    this.directionalLight.position.x = radius * Math.cos(n * DEG2RAD * -1);
    this.directionalLight.position.z = radius * Math.sin(n * DEG2RAD * -1);
    // Update light direction
    this.directionalLight.target.updateMatrixWorld();
  }

  // Sets the rotation of the environment and environment mesh
  public setEnvIntensity(n: number) {
    this.environmentIntensity = n;
    this.envSettings.intensity = n;
    const color = new Color(generateGrayscaleColor(n));
    // console.log('Color generated: ', color);
    if (this.backgroundMesh !== undefined) {
      (this.backgroundMesh.material as MeshStandardMaterial).color = color;
    } else {
      console.warn('*** Cant update material on backgroundMesh');
    }

    if (this.groundedSkybox !== undefined) {
      this.groundedSkybox!.material.color = color;
    } else {
      console.warn('*** Cant update material on groundedSkybox');
    }

    if (this.groundedSkybox !== undefined) {
      (this.shadowCatchingPlane.material as ShadowMaterial).opacity = (2 / 3) * parseFloat(String(n));
    } else {
      console.warn('*** Cant update material on shadowCatchingPlane');
    }

    this.directionalLight.intensity = parseFloat(String(n));
  }

  // Endable the grounded environment
  public setFloorProjection(b: boolean) {
    this.envSettings.floorProjection = b;
    if (b) {
      this.groundedSkybox && this.add(this.groundedSkybox);
      this.shadowCatchingPlane && this.add(this.shadowCatchingPlane);

      this.removeFromScene(this.backgroundMesh);
    } else {
      this.groundedSkybox && this.remove(this.groundedSkybox);
      this.shadowCatchingPlane && this.remove(this.shadowCatchingPlane);

      this.addToScene(this.backgroundMesh);
    }
  }

  // Adds a cinematic camera the UI and manages it in the `camerasInUI` collection.
  public addCameraToUI(id: string, camera: CameraInfo, sceneName?: string) {
    let max = 0;
    // If no scene name is provided, calculate the next available default name for the asset.
    sceneName === undefined &&
      this.camerasInUI.forEach((name, ids) => {
        const nb = name.split('Camera ')[1];
        nb && (max = Math.max(max, parseInt(nb)));
      });
    const name = sceneName || `Camera ${max + 1}`;
    // Add the camera to the `asetsInUI` collection with its ID as the key.
    this.camerasInUI.set(id, name);

    // Trigger a callback if available to inform about the addition of the asset to the UI.
    if (this.callbacks) {
      const cb = this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
      if (cb) {
        cb({
          id: id,
          name: name,
          type: ObjectType.CINEMATIC_CAMERA,
          info: camera,
        });
      }
    }
  }

  // Adds a cinematic camera the UI and manages it in the `camerasInUI` collection.
  public addLightToUI(id: string, light: LightInfo, sceneName?: string) {
    let max = 0;
    // If no scene name is provided, calculate the next available default name for the asset.
    sceneName === undefined &&
      this.lightsInUI.forEach((name, ids) => {
        const nb = name.split('Light ')[1];
        nb && (max = Math.max(max, parseInt(nb)));
      });
    const name = sceneName || `Light ${max + 1}`;
    // Add the camera to the `asetsInUI` collection with its ID as the key.
    this.lightsInUI.set(id, name);

    // Trigger a callback if available to inform about the addition of the asset to the UI.
    if (this.callbacks) {
      const cb = this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
      if (cb) {
        cb({
          id: id,
          name: name,
          type: ObjectType.LIGHT,
          info: light,
        });
      }
    }
  }

  // Adds an asset to the UI and manages it in the `asetsInUI` collection.
  public addAssetToUI(id: string, info: AssetModelInfo, sceneName?: string): string {
    let max = 0;

    // If no scene name is provided, calculate the next available default name for the asset.
    sceneName === undefined &&
      this.asetsInUI.forEach((name, ids) => {
        const nb = name.split('Asset ')[1];
        nb && (max = Math.max(max, parseInt(nb)));
      });
    const name = sceneName || `Asset ${max + 1}`;

    // Add the asset to the `asetsInUI` collection with its ID as the key.
    this.asetsInUI.set(id, name);

    // Trigger a callback if available to inform about the addition of the asset to the UI.
    if (this.callbacks) {
      const cb = this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
      if (cb) {
        cb({
          id: id,
          name: name,
          type: ObjectType.ASSET3D,
          info,
        });
      }
    }
    return name;
  }

  public getUserPOVContainer(): Object3D {
    return this.userHolders;
  }

  // Adds a character to the UI and manages it in the `charactersInUI` collection.
  public addCharToUI(id: string, char: CharacterModelInfo, sceneName?: string, liveId?: string): string {
    let max = 0;

    // If no scene name is provided, calculate the next available default name for the character.
    sceneName === undefined &&
      this.charactersInUI.forEach((name, ids) => {
        const nb = name.split('Char ')[1];
        nb && (max = Math.max(max, parseInt(nb)));
      });

    const name = sceneName || `Char ${max + 1}`;
    // console.log('Add to UI: ', id, char);
    // Add the character to the `charactersInUI` collection with its ID as the key.
    this.charactersInUI.set(id, name);

    // Trigger a callback if available to inform about the addition of the character to the UI.
    if (this.callbacks) {
      const cb = this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
      if (cb) {
        cb({
          id: id,
          name: name,
          type: ObjectType.CHARACTER,
          livePlayerId: liveId,
          info: char,
        });
      }
    }
    return name;
  }

  // Changes the name of a 3D character in the user interface (UI) and updates it in the scene.
  public changeCharName(id: string, newName: string) {
    let oldName = '';
    this.charactersInUI.forEach((name, ids) => {
      ids === id && (oldName = name);
    });
    this.charactersInUI.delete(id);
    this.charactersInUI.set(id, newName);

    this.charactersInScene.get(id)?.createText3D(newName, this.sceneNamesVisible);
  }

  // Changes the duration of an action or scene.
  public changeDuration(newDuration: number) {
    this.duration = newDuration;
  }

  // Retrieves the current duration of an action or scene.
  public getDuration(): number {
    return this.duration;
  }

  // Get a unique asset name based on the provided keyword.
  public getAssetName(find: string): string {
    let maxGlobal = 0;
    const getMax = (name: string): number => {
      let maxLocal = 0;
      let nb = name.split(`${find}`)[1];
      nb && (nb = nb.split(')')[0].split('(')[1]);
      name.indexOf(find) >= 0 && (maxLocal = 1);
      console.log(' find name: ', find, ' in name: ', name);
      nb && parseInt(nb) && (maxLocal = Math.max(maxLocal, parseInt(nb) + 1));
      return maxLocal;
    };
    this.charactersInUI.forEach((name, ids) => {
      maxGlobal = Math.max(maxGlobal, getMax(name));
    });
    this.asetsInUI.forEach((name) => {
      maxGlobal = Math.max(maxGlobal, getMax(name));
    });
    this.camerasInUI.forEach((name) => {
      maxGlobal = Math.max(maxGlobal, getMax(name));
    });
    this.lightsInUI.forEach((name) => {
      maxGlobal = Math.max(maxGlobal, getMax(name));
    });
    return `${find} ${maxGlobal > 0 ? `(${maxGlobal})` : ''}`;
  }

  // Returns the next camera name
  public getNextCameraName(): string {
    let nmb = 1;
    this.camerasInScene.forEach((cam) => {
      const prevNmb = cam.name.split('Camera ')[1];
      const n = prevNmb ? parseInt(prevNmb) + 1 : 0;
      nmb = n > nmb ? n : nmb;
    });
    return `Camera ${nmb}`;
  }

  // Returns the next light name
  public getNextLightName(): string {
    let nmb = 1;
    this.lightsInScene.forEach((light) => {
      const prevNmb = light.name.split('Light ')[1];
      const n = prevNmb ? parseInt(prevNmb) + 1 : 0;
      nmb = n > nmb ? n : nmb;
    });
    return `Light ${nmb}`;
  }

  // Adds a cinematic camera to scene
  public addCinematicCamera(cc: CinematicCamera) {
    this.addCameraToUI(cc.uuid, cc.getAssetInfo(), cc.name);
    this.camerasInScene.set(cc.uuid, cc);
    this.add(cc);
  }

  // Adds a cinematic camera to scene
  public removeCinematicCamera(cc: CinematicCamera) {
    this.camerasInScene.delete(cc.uuid);
    this.camerasInUI.delete(cc.uuid);
    this.remove(cc);
  }

  // Adds a light to scene
  public addLight(cc: RadicalPointLight | RadicalSpotLight) {
    this.addLightToUI(cc.uuid, cc.getAssetInfo(), cc.name);
    this.lightsInScene.set(cc.uuid, cc);
    // this.add(cc);
    this.add(cc.helper);
    // this.numTotalLightsAddedToScene++;
  }

  // Removes a light from scene
  public removeLight(cc: Light) {
    this.lightsInScene.delete(cc.uuid);
    this.lightsInUI.delete(cc.uuid);
    // this.remove(cc);
  }

  // Adds a 3D character object to the scene and manages it in the `charactersInScene` collection.
  public addCharacterToScene(obj: RadicalCharacter) {
    // Set the skeleton visibility of the character based on the current state.
    obj.setSkeletonVisible(this.jointsVisible);

    // Retrieve the name associated with the character from the UI if available.
    const name = this.charactersInUI.get(obj.uuid);
    if (name) obj.name = name;

    // Add the character to the `charactersInScene` collection with its UUID as the key.
    this.charactersInScene.set(obj.uuid, obj);

    // Add the character to the scene itself.
    this.add(obj);

    // Trigger a callback if available to inform about the addition of the character to the scene.
    const cb = Array.isArray(this.callbacks) && this.callbacks[CanvasCallbacksNames.OBJECT_ADDED];
    if (cb) {
      cb({
        id: obj.uuid,
        name: obj.name,
        type: ObjectType.CHARACTER,
        locked: obj.isLocked(),
        livePlayerId: obj.getLivePlayerId(),
        info: obj.getAssetInfo(),
      });
    }
  }

  // Retrieves an array of all characters currently in the scene.
  public getAllCharacters(): RadicalCharacter[] {
    return [...this.charactersInScene.values()];
  }

  // Retrieves an array of all lights currently in the scene.
  public getAllLights(): (RadicalPointLight | RadicalSpotLight)[] {
    return [...this.lightsInScene.values()];
  }

  // Retrieves an array of all characters currently in the scene.
  public getAllAssets(): AssetObject[] {
    const assetsOnly: AssetObject[] = [];
    this.assetsInScene.forEach((asset) => {
      if (asset instanceof AssetObject) assetsOnly.push(asset);
    });

    return assetsOnly;
  }

  // Retrieves a character from the scene based on its unique identifier.
  public getCharacterFromScene(id: string): RadicalCharacter | undefined {
    return this.charactersInScene.get(id) || undefined;
  }

  // Toggles the visibility of character joints (skeleton) in the scene.
  public toggleJointsVisibility(b: boolean) {
    this.jointsVisible = b;
    this.charactersInScene.forEach((char) => {
      char.setSkeletonVisible(b);
    });
  }

  // Retrieves the first character in the scene.
  public getFirstCharacter(): RadicalCharacter | undefined {
    return this.charactersInScene.size > 0
      ? this.charactersInScene.get(this.charactersInScene.keys().next().value as string)
      : undefined;
  }

  // 3d assets
  public getAssetFromScene(id: string): AssetObject | Object3D | undefined {
    return this.assetsInScene.get(id) || undefined;
  }

  // Retrieve camera based on id
  public getCameraFromScene(id: string) {
    return this.camerasInScene.get(id);
  }

  // Retrieve all cameras from scene
  public getAllCameras(): Map<string, CinematicCamera> {
    return this.camerasInScene;
  }

  // Retrieve light based on id
  public getLightFromScene(id: string): RadicalSpotLight | RadicalPointLight | undefined {
    return this.lightsInScene.get(id);
  }

  public getAllLightsFromScene(): Map<string, RadicalPointLight | RadicalSpotLight> {
    return this.lightsInScene;
  }

  // SHow or hide the camera cones
  public camerasVisible(b: boolean) {
    this.camerasInScene.forEach((cam) => {
      cam.visible = b;
    });
  }

  // Retrieves the unique identifier of the user.
  public getOwnId(): string | undefined {
    return this.ownId;
  }

  // Calculates and returns the duration of the longest animation among the characters in the scene.
  public calculateLongestDuration(): number {
    let longest = 0;
    this.charactersInScene.forEach((char) => {
      const total = char.getTotalDuration();
      longest = total > longest ? total : longest;
    });
    return longest;
  }

  // Removes an object from the scene and returns a snapshot of the object's state.
  public removeFromScene(obj: Object3D): ObjectSnapshot {
    const snapshot = this.takeSnapshot(obj);
    if (this.assetsInScene.delete(obj.uuid)) {
      this.asetsInUI.delete(obj.uuid);
      if (this.callbacks) {
        const cb = this.callbacks[CanvasCallbacksNames.OBJECT_REMOVED];
        if (cb) {
          // console.log('calling callback');
          cb(obj.uuid);
        }
      }
    }
    this.remove(obj);
    return snapshot;
  }

  // Removes multiple objects from the scene.
  public removeMultipleFromScene(objs: Object3D[]) {
    objs.forEach((obj) => {
      // console.log('removed: ', obj);
      this.remove(obj);
    });
  }

  // Removes a 3D character from the scene by its UUID and returns a snapshot of the character's state.
  public removeCharacterFromScene(uuid: string): ObjectSnapshot {
    const char = this.charactersInScene.get(uuid);
    let result = this.takeSnapshot();

    if (char) {
      this.charactersInUI.delete(uuid);
      // animations?
      if (this.callbacks) {
        const cb = this.callbacks[CanvasCallbacksNames.OBJECT_REMOVED];
        if (cb) {
          // console.log('calling callback');
          cb(uuid);
        }
      }
      result = this.takeSnapshot(char);
      this.charactersInScene.delete(uuid);
      this.remove(char);
      char.clear();
    } else {
      console.warn('Character: ', uuid, 'not found');
    }
    return result;
  }

  // Set the background mesh to show either blank or the texture
  public setIsEnvironment(b: boolean) {
    this.backgroundUniforms.isEnvironment.value = b;
  }

  // Set the visibility of the environment mesh
  public setEnvironmentMeshVisibility(b: boolean) {
    this.backgroundMesh && (this.backgroundMesh.visible = b);
  }

  // Adds a cube texture or texture to the environment, which may be used as the background.
  public addEnvironment(env: CubeTexture | Texture, equirectangularEnv: Texture) {
    if (this.groundedSkybox) {
      this.remove(this.groundedSkybox);
    }
    this.environment = env;
    this.backgroundTexture = equirectangularEnv;
    // @ts-ignore
    this.backgroundMesh.material.map = this.backgroundTexture;
    // @ts-ignore
    this.backgroundMesh.material.needsUpdate = true;

    this.groundedSkybox = new GroundedSkybox(
      equirectangularEnv,
      paramsGroundedSkybox.height,
      paramsGroundedSkybox.radius
    );
    this.groundedSkybox.position.y = paramsGroundedSkybox.height - 0.01;
    this.groundedSkybox.material.onBeforeCompile = (this.backgroundMesh.material as MeshBasicMaterial).onBeforeCompile;
    // grounded skybox on/off
    if (this.envSettings.floorProjection) {
      this.add(this.groundedSkybox);
      // this.add(this.shadowCatchingPlane);
      this.removeFromScene(this.backgroundMesh);
    }
    // set environment intensity
    this.environmentIntensity = this.envSettings.intensity;
    const color = new Color(generateGrayscaleColor(this.envSettings.intensity));
    (this.backgroundMesh.material as MeshStandardMaterial).color = color;
    this.groundedSkybox.material.color = color;
    // set environment rotation
    const rad = MathUtils.degToRad(this.envSettings.rotation);
    this.environmentRotation.set(0, rad, 0);
    this.backgroundMesh.rotation.set(0, Math.PI + rad, 0);
    this.groundedSkybox.rotation.set(0, rad, 0);
    // Set depth test and write for the grounded skybox material to ensure proper rendering
    this.groundedSkybox.material.depthWrite = true;
    this.groundedSkybox.material.depthTest = true;
    this.groundedSkybox.material.needsUpdate = true;

    // console.log('GROUNDED SKYBOX', this.groundedSkybox);
  }

  // Removes the current environment (cube texture or texture) from the scene.
  public removeEnvironment() {
    this.environment = null;
  }

  // Shows or hides the background environment based on the provided flag.
  // public showBackgroundEnvironment(b: boolean) {
  //   this.backgroundEnvVisible = b;
  //   // b ? (this.background = this.environment) : (this.background = null);
  //   //if (this.backgroundTexture) {
  //   // @ts-ignore
  //   this.backgroundMesh.material.map = this.backgroundTexture;
  //   // @ts-ignore
  //   this.backgroundMesh.material.needsUpdate = true;
  //   //}
  //   // this.backgroundUniforms.isEnvironment.value = b;
  // }

  // Retrieves an object from the scene based on its UUID.
  public getObjectByUuid(uuid: string): Object3D | undefined {
    return this.getObjectByProperty('uuid', uuid);
  }

  // Adds fog to the scene with the specified color.
  public addFog(col: ColorRepresentation): void {
    const color = col;
    const near = 25;
    const far = 30;
    this.fog = new Fog(color, near, far);
  }

  // Set floor visibility
  public showFloor(b: boolean): void {
    this.floor && (this.floor.visible = b);
  }

  // Changes a camera based on information from command
  public changeCinematicCamera(id: string, camInfo: Partial<CameraInfo>, aspect: { w: number; h: number }) {
    const camera = this.getCameraFromScene(id);
    if (camera === undefined) return console.warn('Camera not found!');

    if (camInfo.fov && camInfo.lens) {
      // change camera fov
      camera.changeCameraFOV(camInfo.fov, camInfo.lens, aspect);
    }
  }

  // Changes the list of users in the scene, either adding or removing a user.
  public changeUserList({ user, added }: { user: VisitorInfo; added: boolean }): void {
    if (added) {
      // If a user is added, create a UserCamera and add it to the scene.
      const {
        location: { position, quaternion },
        thisClient,
        attendeeId,
        color,
      } = user;

      thisClient ? (this.ownId = attendeeId) : null;
      const p = new Vector3(position.x, position.y, position.z);
      const q = new Quaternion(quaternion.x, quaternion.y, quaternion.z, quaternion.w);
      const c = new UserCamera(attendeeId, 10, color);
      c.position.copy(p);
      c.quaternion.copy(q);
      c.visible = this.userPovVisible;

      !thisClient ? this.userHolders.add(c) : null;
      this.usersInScene.set(attendeeId, { cone: c, visitor: user });
    } else {
      // If a user is removed, remove their UserCamera from the scene.
      const c = this.usersInScene.get(user.attendeeId)?.cone;
      if (c) {
        this.userHolders.remove(c);
        this.usersInScene.delete(user.attendeeId);
      }
    }
  }

  // Retrieves the color of a user in the scene based on their unique identifier.
  public getUserColor(id: string): string | undefined {
    if (this.usersInScene.has(id)) {
      return this.usersInScene.get(id)?.visitor?.color;
    } else {
      console.warn('User color not found: ', id);
      return undefined;
    }
  }

  // Toggles the visibility of UserCameras in the scene.
  public toggleUserCameras(b: boolean) {
    this.userPovVisible = b;
    this.usersInScene.forEach((user) => {
      user.cone.visible = b;
    });
  }

  // Updates the camera position, rotation, field of view, and target of a user in the scene.
  public updateUserCamera(id: string, pos: Vector3, rot: Quaternion, fov: number, tgt: Vector3) {
    const c = this.usersInScene.get(id);
    if (c) {
      // Retrieve the UserCamera associated with the user ID.

      // Update the position and rotation of the UserCamera.
      c.cone.position.copy(pos);
      c.cone.quaternion.copy(rot);

      // Update the user's location information including position and rotation.
      c.visitor.location.position = pos;
      c.visitor.location.quaternion = rot;

      // Update the target position of the user's camera.
      c.visitor.location.target = tgt;
    } else {
      // If the user's camera is not found, log a warning.
      console.log('Audience cam not found: ', id);
    }
  }

  // Starts following a user in the scene by their unique identifier.
  public followUser(id: string | undefined): boolean {
    this.stopFollowing();
    this.followingUser = id ? this.usersInScene.get(id) : undefined;
    if (this.followingUser) {
      // Hide the UserCamera of the followed user.
      this.followingUser.cone && (this.followingUser.cone.visible = false);
      return true;
    }
    return false;
  }

  // Stops following the currently followed user (if any) and makes their camera visible again.
  public stopFollowing() {
    this.followingUser ? this.followingUser.cone && (this.followingUser.cone.visible = true) : null;
    this.followingUser = undefined;
  }

  // Handles user following events received from a websocket.
  public userFollowing(user: string, follow: string | undefined) {
    // Get the User's 3D representation from the scene.
    const u = this.usersInScene.get(user);

    // If the user is not found in the scene, return early.
    if (u === undefined) return;

    // If the user performing the action is the same as the current user (self), hide their camera.
    if (user === this.ownId) {
      u.cone.visible = false;
      return;
    }

    // If 'follow' is provided (user is following someone), hide the user's camera.
    if (follow) {
      u.cone.visible = false;
    } else {
      // If not following anyone, determine camera visibility based on the currently followed user.
      if (this.followingUser) {
        // If the user is not following the currently followed user, show their camera; otherwise, hide it.
        u.cone.visible = this.followingUser.visitor.attendeeId === user ? false : true;
      } else {
        // If no user is currently being followed, show the camera.
        u.cone.visible = true;
      }
    }

    // If the user is being followed by the current user (self), hide their camera.
    if (follow === this.ownId) {
      u.cone.visible = false;
    } else if (follow === undefined) {
      // If not following anyone, determine camera visibility based on the currently followed user.
      if (this.followingUser) {
        // If the user is not following the currently followed user, show their camera; otherwise, hide it.
        if (this.followingUser.visitor.attendeeId !== user) u.cone.visible = true;
        else u.cone.visible = false;
      } else u.cone.visible = true;
    } else {
      // Output a log message indicating that the user is following another user.
      console.log(`User ${user} starts following ${follow}`);

      // If the current user is already following the user being followed, hide their camera.
      if (this.followingUser && follow === this.followingUser.visitor.attendeeId) {
        u.cone.visible = false;
      }
    }

    // Update the following status for the user.
    u.visitor.following = follow;
  }

  // Gets the user that is currently being followed.
  public getFollowed(): Visitor3D | undefined {
    return this.followingUser;
  }

  // Takes a snapshot of the position, quaternion, and scale of an Object3D.
  private takeSnapshot(o?: Object3D): ObjectSnapshot {
    return {
      position: o ? o.position.clone() : undefined,
      quaternion: o ? o.quaternion.clone() : undefined,
      scale: o?.scale.clone(),
    };
  }

  // Creates a loader mesh with wireframe visualization and a fading shader.
  // This mesh is used for loading animations in the scene.
  private createLoaderMesh(): Object3D {
    // Define a custom shader for the loader mesh.
    const timeLineShader = {
      uniforms: {
        color: { value: new Color(0x0081d1) }, // Color for the loader.
        time: { value: 0 }, // Time parameter for fading effect.
      },
      vertexShader: `
        attribute float count;
        varying float vCount;
        void main() {
          vCount = count;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
        }
      `,
      fragmentShader: `
        #include <common>

        varying float vCount;
        uniform vec3 color;
        uniform float time;

        void main() {
          if (vCount > time) {
            discard;
          }
          gl_FragColor = vec4(color, 0.5);
        }
      `,
    };

    // Create a ShaderMaterial with the defined shader.
    const material = new ShaderMaterial(timeLineShader);
    material.transparent = true;
    material.opacity = 0.5;

    // Create a GLTFLoader instance.
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
    loader.setDRACOLoader(dracoLoader);
    const obj = new Object3D();
    let wireframeGeometry: WireframeGeometry;

    // Load a placeholder 3D model file using the loader.
    loader.load(filePathCharPlaceholder, (gltf) => {
      gltf.scene.scale.set(0.004, 0.004, 0.004);
      gltf.scene.scale.set(0.01, 0.01, 0.01);
      gltf.scene.position.y = 1.05;

      // Traverse through the loaded model's children to create wireframe representations.
      gltf.scene.traverse((child: any) => {
        if (child instanceof Mesh) {
          child.material.wireframe = true;
          wireframeGeometry = new WireframeGeometry(child.geometry);

          const numVertices = wireframeGeometry.getAttribute('position').count;
          const counts = new Float32Array(numVertices);

          // every 2 points is one line segment so we want the numbers to go
          // 0, 1, 1, 2, 2, 3, 3, 4, 4, 5 etc

          // Define line segments by connecting every two points.
          const numSegments = numVertices / 2;
          for (let seg = 0; seg < numSegments; ++seg) {
            const off = seg * 2;
            counts[off + 0] = seg;
            counts[off + 1] = seg + 1;
          }

          const itemSize = 1;
          const normalized = false;
          const colorAttrib = new BufferAttribute(counts, itemSize, normalized);
          wireframeGeometry.setAttribute('count', colorAttrib);

          const line = new LineSegments(wireframeGeometry);
          line.rotation.x = -Math.PI / 2;
          line.rotation.z = -Math.PI / 2;
          line.material = material;
          line.position.y = 1.05;

          const line2 = line.clone();
          line.name = 'main';
          line2.material = new LineBasicMaterial({ color: 0x0081d1, transparent: true, opacity: 0.1 }); // material.clone();
          obj.add(line);
          obj.add(line2);
        }
      });
    });
    return obj;
  }

  // Creates a visualizer floor by loading a 3D model.
  // TODO: Create it only for visualizer
  private createVisualizerFloor(isLive?: boolean): Object3D {
    // Create a GLTFLoader instance.
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/');
    loader.setDRACOLoader(dracoLoader);
    const obj = new Object3D();

    // Load the 3D model file for the visualizer floor.
    loader.load(filePathVisualizerPlane, (gltf) => {
      // Traverse through the loaded model's children to configure materials and shadows.
      gltf.scene.traverse((child: any) => {
        if (child.material) {
          // Edit the shader of the material
          // This is to handle the selective bloom on this object
          // So we can isolate objects not needing bloom
          // Modify the shader used by the mesh's material
          child.material.onBeforeCompile = (shader: any) => {
            // Specify the GLSL version
            shader.glslVersion = GLSL3;

            // Modify the fragment shader to include a custom uniform for controlling bloom intensity
            shader.fragmentShader = shader.fragmentShader.replace(
              `#include <common>`,
              `#include <common>
            uniform float bloomLayer;
            layout(location = 0) out vec4 base_FragColor;
            layout(location = 1) out vec4 bloom_FragColor;
            layout(location = 2) out vec4 depth_FragColor;
            layout(location = 3) out vec4 id_FragColor;
            `
            );

            shader.fragmentShader = shader.fragmentShader.replace(
              `#include <opaque_fragment>`,

              `#ifdef OPAQUE
            diffuseColor.a = 1.0;
            #endif
            
            #ifdef USE_TRANSMISSION
            diffuseColor.a *= material.transmissionAlpha;
            #endif

            float cameraNear = 0.1; //hard coded for now
            float cameraFar = 40.0;
            float depth = gl_FragCoord.z / gl_FragCoord.w;
            float depthMapped = smoothstep(cameraNear, cameraFar, depth);
            
            bloom_FragColor = vec4( 0.0 );
            base_FragColor = vec4( outgoingLight, diffuseColor.a );
            depth_FragColor = vec4( 0.0 );
            id_FragColor = vec4( 0.0 );
            `
            );

            // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
            // These sections customize how the final color outputs are processed and rendered

            shader.fragmentShader = shader.fragmentShader.replace(
              `#include <tonemapping_fragment>`,
              `#if defined( TONE_MAPPING )
              bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
              base_FragColor.rgb = toneMapping( base_FragColor.rgb );
            #endif`
            );

            shader.fragmentShader = shader.fragmentShader.replace(
              `#include <colorspace_fragment>`,
              `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
            base_FragColor = linearToOutputTexel( base_FragColor );
            `
            );

            shader.fragmentShader = shader.fragmentShader.replace(
              `#include <fog_fragment>`,
              `#ifdef USE_FOG
              #ifdef FOG_EXP2
                float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
              #else
                float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
              #endif
              bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
              base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
          #endif`
            );

            shader.fragmentShader = shader.fragmentShader.replace(
              `#include <premultiplied_alpha_fragment>`,
              `#ifdef PREMULTIPLIED_ALPHA
              // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
              bloom_FragColor.rgb *= bloom_FragColor.a;
              base_FragColor.rgb *= base_FragColor.a;
            #endif`
            );

            shader.fragmentShader = shader.fragmentShader.replace(
              `#include <dithering_fragment>`,
              `#ifdef DITHERING
              bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
              base_FragColor.rgb = dithering( base_FragColor.rgb );
            #endif`
            );
          };

          child.castShadow = false;
          child.receiveShadow = true;
          child.material.envMapIntensity = 1;
        }
      });
      isLive && gltf.scene.scale.set(2, 2, 2);
      obj.add(gltf.scene);
    });
    obj.position.y = -0.02;
    return obj;
  }

  public destroy() {
    delete this.callbacks;
  }
}
