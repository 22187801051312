import {
  Color,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  Quaternion,
  SphereGeometry,
  Vector3,
  GLSL3,
  BoxGeometry,
  BufferGeometry,
  LineBasicMaterial,
  LineSegments,
  Uint8BufferAttribute,
  MathUtils,
} from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

// Import the file path for the user's camera model
//@ts-ignore
import filePath from '../../public/assets/camera_object_curve_bodyonly.fbx';
import { CameraInfo } from '@radical/canvas-types';

// const Colors = {
//   RED: 16711680,
//   BLUE: 255,
//   LIME: 65280,
// };

const Colors = {
  RED: 0,
  BLUE: 0,
  LIME: 0,
};

// const frameWidth = 36; // mm, 36x24mm - camera film

// Create a CinematicCamera class that extends Object3D
export class CinematicCamera extends Object3D {
  // private object: Object3D;
  private meshHitter: Mesh;
  private camera: CameraInfo;
  private fov: number;
  private calculatedFOV: number;
  private frustum: LineSegments;
  private target: Vector3 = new Vector3(0, 0, 2);
  private tgtMesh: Mesh;
  private object: Object3D;
  private frameWidth: number = 36;

  private uniforms: {
    isHovered: { value: number };
  };

  constructor(id: string, camera: CameraInfo) {
    super();
    this.name = camera.name;
    this.uuid = id;
    this.visible = true;
    this.camera = camera;
    this.fov = camera.fov;
    const focalLength = this.fov;
    this.calculatedFOV = MathUtils.radToDeg(2 * Math.atan(this.frameWidth / (focalLength * 2)));
    const { position, quaternion } = camera.info;
    this.position.copy(new Vector3(position.x, position.y, position.z));
    this.quaternion.copy(new Quaternion(quaternion.x, quaternion.y, quaternion.z, quaternion.w));

    // (Math.atan(Math.tan(((this.fov / 2) * Math.PI) / 180) / 1.33) * 2 * 180) / Math.PI;
    // 2 * Math.atan(10 / (2 * this.fov));
    // console.log('Calculated: ', this.calculatedFOV);
    // Load the user's camera model and create a sphere
    this.object = this.loadUserFile('#000000');
    this.object.quaternion.set(0, 1, 0, 0);

    this.meshHitter = this.createSphere('#000000');
    this.meshHitter.position.y = 0.01;
    this.meshHitter.renderOrder = 1;
    this.frustum = this.createFrustumFOV(this.calculatedFOV, 16 / 9, 0.15, 1.5);
    this.frustum.renderOrder = 2;
    // this.frustum.quaternion.set(0,1,0,0);
    // Add the camera model and sphere to the UserCamera object
    const m = new Mesh(new SphereGeometry(0.01), new MeshBasicMaterial({ color: 0xff0000 }));
    m.position.copy(this.target);
    m.visible = false;
    this.tgtMesh = m;
    this.add(m);
    this.frustum.position.z = -0.1;
    this.add(this.meshHitter);
    this.add(this.frustum);
    this.add(this.object);

    this.uniforms = {
      isHovered: { value: 0.0 },
    };

    this.traverse((child: any) => {
      // Check if the child has a material property
      if (child.material) {
        // Modify the shader right before it compiles
        child.material.onBeforeCompile = (shader: any) => {
          // Update the shader with custom modifications
          shader = this.updateShader(0.0, shader);
        };
      }
    });
  }

  public getFOV(): number {
    return this.calculatedFOV;
  }

  public getHitter(): Mesh {
    return this.meshHitter;
  }

  public getAssetInfo(): CameraInfo {
    return this.camera;
  }

  public getTarget(): Vector3 {
    return this.target;
  }

  public toggleHelper(show: boolean) {
    this.object.visible = show;
    this.frustum.visible = show;
  }

  public getTargetWorld(): Vector3 {
    const tgt = new Vector3();
    return this.tgtMesh.getWorldPosition(tgt);
  }

  public changeCameraFOV(newFov: number, lens: string, aspect?: { w: number; h: number }) {
    this.fov = newFov;
    this.camera.fov = newFov;
    this.camera.lens = lens;
    const aspectRatio = aspect ? aspect.w / aspect.h : 16 / 9;
    this.frameWidth = aspect ? (aspect.w === 16 ? 36 : 48) : 36;
    this.calculatedFOV = MathUtils.radToDeg(2 * Math.atan(this.frameWidth / (newFov * 2)));
    this.remove(this.frustum);
    this.frustum = this.createFrustumFOV(this.calculatedFOV, aspectRatio, 0.15, 1.5);
    this.frustum.traverse((child: any) => {
      // Check if the child has a material property
      if (child.material) {
        // Modify the shader right before it compiles
        child.material.onBeforeCompile = (shader: any) => {
          // Update the shader with custom modifications
          shader = this.updateShader(0.0, shader);
        };
      }
    });
    this.frustum.renderOrder = 2;
    this.add(this.frustum);
    this.frustum.position.z = -0.1;
    console.log('Calculated FOV: ', this.calculatedFOV);
  }

  // Private method to load the cinematic camera model
  private loadUserFile(color: string): Object3D {
    const loader = new FBXLoader();
    const obj = new Object3D();

    // Load the FBX file and modify its properties
    loader.load(filePath, (obj) => {
      this.object.add(obj);

      // Traverse through the object and set material properties
      obj.traverse((obj) => {
        if ((obj as any).material) {
          (obj as any).material.color = new Color(color);
          (obj as any).material.emissive = new Color(color);
          (obj as any).material.emissiveIntensity = 1;
          (obj as any).material.transparent = true;
          // This editing below activates the occlusion of the POV in the bloom pass composer
          // Commented, for future references
          (obj as any).material.onBeforeCompile = (shader: any) => {
            shader = this.updateShader(1.0, shader);
          };
          (obj as any).material.needsUpdate = true;
        }
        if ((obj as any).isMesh) {
          (obj as any).material.transparent = true;
          (obj as any).material.opacity = 1; // = null;
        }
      });

      // Set the scale and position of the loaded object
      obj.scale.set(0.0023, 0.0023, 0.0023);
      obj.position.z = 0;
    });
    return obj;
  }

  // Private method to create a sphere mesh
  private createSphere(color: string): Mesh {
    const g = new BoxGeometry(0.55, 0.37, 0.2);
    const m = new MeshBasicMaterial({ color: new Color(color), transparent: true, opacity: 0 });
    const mesh = new Mesh(g, m);

    // This editing below activates the occlusion of the POV in the bloom pass composer
    // Commented, for future references
    // mesh.material.onBeforeCompile = (shader: any) => {
    //   shader = this.updateShader(shader);
    // }

    // Associate the sphere mesh with this UserCamera
    (mesh as any).outlineParent = this;
    return mesh;
  }

  private createFrustumFOV(fovy: number, aspectRatio: number, near: number, far: number): LineSegments {
    var top = near * Math.tan(this.degrees2radians(fovy) / 2);
    var bottom = -top;
    var right = aspectRatio * top;
    var left = -right;
    return this.createFrustum(right, left, top, bottom, near, far);
  }

  private createFrustum(
    left: number,
    right: number,
    top: number,
    bottom: number,
    near: number,
    far: number
  ): LineSegments {
    /* returns a THREE.Line object representing a frustum, like a
     * camera. Position is at the origin, and args are just like in
     * OpenGL's glFrustum(). The near plane is drawn with red lines, and
     * the far with green, and blue lines connecting. */
    // I loosely modeled this code on the CameraHelper
    var geometry = new BufferGeometry();
    const points: Vector3[] = [];
    let colors: number[] = [];
    // ratio by which far coordinates are larger than near
    var r = far / near;
    // three letter abbrevs: l/r for left/right, t/b for top/bottom, n/f for near/far
    var pointMap: any = {
      ltn: new Vector3(left, top, near),
      lbn: new Vector3(left, bottom, near),
      rbn: new Vector3(right, bottom, near),
      rtn: new Vector3(right, top, near),
      ltf: new Vector3(r * left, r * top, far),
      lbf: new Vector3(r * left, r * bottom, far),
      rbf: new Vector3(r * right, r * bottom, far),
      rtf: new Vector3(r * right, r * top, far),
    };

    function addLine(a: string, b: string, color: number) {
      // adds a line from a to b in given color
      addPoint(a, color);
      addPoint(b, color);
    }

    function addPoint(id: string, color: number) {
      var v = new Vector3();
      v.copy(pointMap[id]);
      points.push(v);
      colors = colors.concat(new Color(color).toArray());
    }

    addLine('ltn', 'lbn', Colors.BLUE);
    addLine('lbn', 'rbn', Colors.BLUE);
    addLine('rbn', 'rtn', Colors.BLUE);
    addLine('rtn', 'ltn', Colors.BLUE);

    addLine('ltf', 'lbf', Colors.BLUE);
    addLine('lbf', 'rbf', Colors.BLUE);
    addLine('rbf', 'rtf', Colors.BLUE);
    addLine('rtf', 'ltf', Colors.BLUE);

    addLine('ltf', 'ltn', Colors.RED);
    addLine('lbf', 'lbn', Colors.RED);
    addLine('rbf', 'rbn', Colors.RED);
    addLine('rtf', 'rtn', Colors.RED);

    geometry.setFromPoints(points);
    colors = colors.map((color) => color * 255);
    geometry.setAttribute('color', new Uint8BufferAttribute(colors, 3, true));
    const material = new LineBasicMaterial({ vertexColors: true });

    // console.log('Colors: ', colors);
    // was  this:
    // var line = new THREE.Line( geometry, material, THREE.LinePieces );
    const line = new LineSegments(geometry, material);
    return line;
  }

  private degrees2radians(degrees: number) {
    return (Math.PI / 180.0) * degrees;
  }

  private updateShader(isHoverable: number, shader: any) {

    // Specify the GLSL version
    shader.glslVersion = GLSL3;

    shader.uniforms = {
      ...shader.uniforms,
      ...this.uniforms,
    };

    // Modify the fragment shader to include a custom uniform for controlling bloom intensity
    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <common>`,
      `#include <common>
      uniform float isHovered;
      layout(location = 0) out vec4 base_FragColor;
      layout(location = 1) out vec4 bloom_FragColor;
      layout(location = 2) out vec4 depth_FragColor;
      layout(location = 3) out vec4 id_FragColor;
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <opaque_fragment>`,

      `#ifdef OPAQUE
      diffuseColor.a = 1.0;
      #endif
      
      #ifdef USE_TRANSMISSION
      diffuseColor.a *= material.transmissionAlpha;
      #endif

      vec4 objectColor = vec4( outgoingLight, diffuseColor.a );

      vec4 hoveringColor = vec4(1.0);
      
      base_FragColor = mix(objectColor, hoveringColor, isHovered * float(${isHoverable}));//vec4( outgoingLight, diffuseColor.a );
      bloom_FragColor = vec4( outgoingLight, 0.0 );
      depth_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
      id_FragColor = vec4( 0.0, 0.0, 0.0, 0.0 );
      `
    );

    // Additional fragment shader replacements for tone mapping, color space conversion, fog, premultiplied alpha, and dithering
    // These sections customize how the final color outputs are processed and rendered

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <tonemapping_fragment>`,
      `#if defined( TONE_MAPPING )
        bloom_FragColor.rgb = toneMapping( bloom_FragColor.rgb );
        base_FragColor.rgb = toneMapping( base_FragColor.rgb );
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <colorspace_fragment>`,
      `bloom_FragColor = linearToOutputTexel( bloom_FragColor );
      base_FragColor = linearToOutputTexel( base_FragColor );
      `
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <fog_fragment>`,
      `#ifdef USE_FOG
        #ifdef FOG_EXP2
          float fogFactor = 1.0 - exp( - fogDensity * fogDensity * vFogDepth * vFogDepth );
        #else
          float fogFactor = smoothstep( fogNear, fogFar, vFogDepth );
        #endif
        bloom_FragColor.rgb = mix( bloom_FragColor.rgb, fogColor, fogFactor );
        base_FragColor.rgb = mix( base_FragColor.rgb, fogColor, fogFactor );
    #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <premultiplied_alpha_fragment>`,
      `#ifdef PREMULTIPLIED_ALPHA
        // Get get normal blending with premultipled, use with CustomBlending, OneFactor, OneMinusSrcAlphaFactor, AddEquation.
        bloom_FragColor.rgb *= bloom_FragColor.a;
        base_FragColor.rgb *= base_FragColor.a;
      #endif`
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <dithering_fragment>`,
      `#ifdef DITHERING
        bloom_FragColor.rgb = dithering( bloom_FragColor.rgb );
        base_FragColor.rgb = dithering( base_FragColor.rgb );
      #endif`
    );

    return shader;
  }

  public setIsHovered(value: number) {
    this.uniforms.isHovered.value = value;
  }

  public setIsSelected(value: boolean) {
  }

  public setSelectionBoxColor(value: string | null) {
  }
}
